import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Grid, Hidden, Button, Typography, Link } from "@material-ui/core"
import { callAnalyticsEvent } from "../utils"
import { useLocation } from "@reach/router"
import useNavbarStyles from "./navbarStyles"
import logo from "../assets/logo-5.png" // Adjust the import path as necessary
import logoSmall from "../assets/favicon.png" // Adjust the import path as necessary
import useAuth from "../context/auth"

// eslint-disable-next-line react/prop-types
const Navbar = ({ variant }) => {
  const location = useLocation()
  const classes = useNavbarStyles(location.pathname)
  const { isAuthenticated } = useAuth()
  const [isChatWindow, setIsChatWindow] = useState(false)

  useEffect(() => {
    if (location.pathname.includes("/chat")) {
      setIsChatWindow(true)
    }
  }, [isChatWindow])

  return (
    <Grid container justify="space-between" className={classes.navbarContainer}>
      <Grid item>
        <div onClick={() => navigate("/")}>
          <Hidden mdDown>
            <img src={logo} alt="Alchtec.com" className={classes.logo} />
          </Hidden>
          <Hidden mdUp>
            <img
              src={logoSmall}
              alt="Alchtec.com"
              className={classes.logoSmall}
            />
          </Hidden>
        </div>
      </Grid>
      {!isChatWindow && (
        <Grid item className={classes.zIndex}>
          <Hidden mdDown>
            <Grid container spacing={2}>
              <div
                onClick={() =>
                  document.getElementById("servicios").scrollIntoView()
                }
              >
                <Typography
                  color="primary"
                  variant="h5"
                  className={classes.item}
                  style={{
                    color: "#000",
                  }}
                >
                  Servicios
                </Typography>
              </div>
              <div
                onClick={() =>
                  document.getElementById("soluciones").scrollIntoView()
                }
              >
                <Typography
                  color="primary"
                  variant="h5"
                  className={classes.item}
                  style={{
                    color: "#000",
                  }}
                >
                  Soluciones
                </Typography>
              </div>
              <div onClick={() => navigate("/privacidad")}>
                <Typography
                  color="primary"
                  variant="h5"
                  className={classes.item}
                  style={{
                    color: "#000",
                  }}
                >
                  Privacidad
                </Typography>
              </div>
              {!isAuthenticated ? (
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate("/login")
                    callAnalyticsEvent("navigate.login")
                  }}
                >
                  <Typography className={classes.buttonLabel}>
                    Iniciar Sesión
                  </Typography>
                </Button>
              ) : (
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate("/accountAdmin")
                  }}
                >
                  <Typography className={classes.buttonLabel}>
                    Ir a la App
                  </Typography>
                </Button>
              )}
            </Grid>
          </Hidden>

          <Hidden mdUp>
            <Grid container alignItems="center" style={{ marginTop: 6 }}>
              {!isAuthenticated ? (
                <Link
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate("/login")
                  }}
                  style={{ fontSize: "1.25rem", color: "#000" }}
                >
                  Iniciar sesión
                </Link>
              ) : (
                <Link
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate("/accountAdmin")
                  }}
                  style={{ fontSize: "1.25rem", color: "#000" }}
                >
                  Ir a la App
                </Link>
              )}
            </Grid>
          </Hidden>
        </Grid>
      )}
    </Grid>
  )
}

export default Navbar
