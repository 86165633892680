// navbarStyles.js
import { makeStyles } from "@material-ui/core/styles"

const useNavbarStyles = makeStyles((theme) => ({
  navbarContainer: {
    height: 57,
    position: "relative",
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 6,
    paddingRight: 6,
    borderBottomStyle: "solid",
    borderBottomColor: "#fff",
  },
  buttonLabel: {
    fontWeight: 500,
    textTransform: "uppercase",
    fontFamily: "Gilroy Bold",
  },
  logo: {
    cursor: "pointer",
    width: 110,
  },
  logoSmall: {
    cursor: "pointer",
    width: 40,
  },
  zIndex: {
    zIndex: 50,
    marginTop: 6,
  },
  item: {
    fontWeight: 800,
    cursor: "pointer",
    margin: 6,
    paddingTop: 9,
    marginLeft: 24,
    fontSize: 16,

    fontWeight: 900,
  },
  button: {
    borderRadius: "15px",
    zIndex: 203,
    height: 40,
    marginTop: 6,
    fontSize: 14,
    fontWeight: 800,
    marginRight: 12,
    marginLeft: 12,
    color: "#fff",
    textTransform: "capitalize",
  },
}))

export default useNavbarStyles
