import {
  ThemeProvider,
  Card,
  Paper,
  Avatar,
  Grid,
  makeStyles,
} from "@material-ui/core"
import { AuthProvider } from "../context/auth"
import theme from "../context/theme"
import AuthorizedPageWrapper from "../context/AuthorizedPageWrapper"
import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import Navbar from "../components/Navbar"
import { CssBaseline } from "@material-ui/core"
import "./styles.css"
import Footer from "../components/Footer"
import { SubscriptionsProvider } from "../context/SubscriptionsContext"
import AssistantWidget from "../components/AgentWidget/AssistantWidget"
import { useSpring } from "@react-spring/web"
import { BASE_AGENT_ID } from "../utils/consts"

export const BasicLayout = ({ children, setOpenChat, openChat }) => {
  return (
    <AuthProvider>
      <ThemeProvider setOpenChat={setOpenChat} theme={theme}>
        {children}
      </ThemeProvider>
    </AuthProvider>
  )
}

export const AuthorizedLayout = ({ children, setOpenChat, openChat }) => {
  return (
    <BasicLayout>
      <AuthorizedPageWrapper setOpenChat={setOpenChat} openChat={openChat}>
        {children}
      </AuthorizedPageWrapper>
    </BasicLayout>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    minHeight: "100vh",
    padding: 0,
    position: "relative", // Add relative position to allow absolute positioning inside
  },
  fabPaper: {
    position: "fixed",
    right: 90,
    bottom: 20,
    padding: 10,
    borderRadius: 50,
    backgroundColor: "#f5f5f5",
    [theme.breakpoints.down("md")]: {
      bottom: "3vh",
    },
  },
  fab: {
    position: "fixed",
    right: 20,
    bottom: 20,
    [theme.breakpoints.down("md")]: {
      bottom: "3vh",
    },
  },
  fabIcon: {
    color: "#fff",
    fontSize: 20,
  },
  agentCard: {
    position: "fixed", // Changed position to 'fixed'
    right: 50,
    bottom: 90,
    width: 400,
    height: 700,
    backgroundColor: "white",
    zIndex: 1000,
    borderRadius: "16px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",

    [theme.breakpoints.down("md")]: {
      right: 0,
      left: 0,
      top: 0,
      width: "100vw",
      height: "100vh",
      borderRadius: 0,
    },
  },
}))

export const NavbarLayout = ({
  children,
  setOpenChat,
  openChat,
  agentId,
  persistOpenChat,
  fullScreen,
}) => {
  const classes = useStyles()
  const [isChatWindow, setIsChatWindow] = useState(false)

  useEffect(() => {
    if (location.pathname.includes("/chat")) {
      setIsChatWindow(true)
    }
  }, [])

  const Content = ({ setOpenChat, openChat }) => {
    const handleChatOpen = () => {
      setOpenChat(!openChat) // Function to open the chat window
    }

    const fade = useSpring({
      opacity: !openChat && !isChatWindow ? 1 : 0,
      config: { duration: 300 },
    })
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Helmet>
          <title>
            Alchtec.com - ¿Quieres saber como implementar IA generativa en tu
            empresa?
          </title>
          <meta
            name="description"
            content="¿Quieres saber como implementar IA generativa en tu empresa? -  Descubre cómo la IA puede optimizar procesos y catapultar la
            innovación en tu negocio."
          />
          <meta
            name="keywords"
            content="IA generativa, tecnología, empresa, innovación, implementar, enterprise, business, artificial intelligence, generative AI, technology, innovation, implement"
          />
          <meta name="author" content="Alchtec" />
        </Helmet>

        <div>
          <Navbar />
          <div>{children}</div>
          <Footer />
        </div>
        <AssistantWidget
          openChat={openChat}
          setOpenChat={setOpenChat}
          persistOpenChat={persistOpenChat}
          fullScreen={fullScreen}
          agentId={agentId || BASE_AGENT_ID}
        />
      </div>
    )
  }

  return (
    <AuthProvider>
      <SubscriptionsProvider>
        <ThemeProvider theme={theme}>
          <Content setOpenChat={setOpenChat} openChat={openChat} />
        </ThemeProvider>
      </SubscriptionsProvider>
    </AuthProvider>
  )
}
